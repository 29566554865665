<template>
  <administration-layout>
    <div class="flex flex-col items-center py-4">
      <div class="w-full xl:px-36 sm:px-8">
        <div class="flex flex-row items-center py-4">
          <h1 class="text-lg font-bold inline-flex items-center">
            <strong>&nbsp;Seguridad</strong>
          </h1>
          <div class="ml-auto">
            <sa-button type="primary" class="items-center px-3 py-3 font-bold rounded" @click="save"
              v-loading.fullscreen.lock="loading">
              Guardar
            </sa-button>
          </div>
        </div>
        <div class="flex flex-col space-y-6">
          <el-form :model="form" :rules="rules" ref="myDataForm" v-loading="loading">
            <content-card>
              <div class="w-full flex">
                <el-form-item class="w-1/3 mr-2" prop="name" autocomplete="off">
                  <strong>Contraseña</strong>
                  <el-input :type="showPassword ? 'text' : 'password'" v-model="form.password" autocomplete="off">
                    <span class="el-input__icon cursor-pointer text-sm hover:cursor-pointer flex items-center"
                      :class="showPassword ? 'text-red-400 hover:text-red-400' : 'text-purple-500 hover:text-purple-400'"
                      slot="suffix" @click="showPassword = !showPassword">
                      <i :class="showPassword ? 'text-xl bx bx-show text-red-500' : 'text-xl bx bx-hide'"></i>
                    </span>
                  </el-input>
                </el-form-item>
              </div>
            </content-card>
          </el-form>
        </div>
      </div>
    </div>
  </administration-layout>
</template>
<script>
import _ from 'lodash';
import error from '@/mixins/error';
import AdminService from '@/services/AdminService';

export default {
  name: 'Security',
  mixins: [
    error,
  ],
  components: {
    AdministrationLayout: () => import('@/components/organisms/TheAdministrationApp'),
    ContentCard: () => import('@/components/molecules/ContentCard'),
  },
  data() {
    return {
      form: {
        password: undefined,
      },
      rules: {},
      loading: false,
      errorMessage: undefined,
      showPassword: false,
    }
  },
  watch: {
    'errorMessage': {
      deep: true,
      handler: _.debounce(async function () {
        if (this.errorMessage) {
          await this.$toastr.e(this.errorMessage);
          this.errorMessage = undefined;
        }
      }, 300)
    },
  },
  methods: {
    async save() {
      if (!this.form.password) {
        this.errorMessage = 'Por favor ingresa una contraseña';
        return;
      }
      this.loading = true;
      try {
        await AdminService.Security.update({ password: this.form.password });
        this.$toastr.s('Se actualizo con exito');
      } catch (e) {
        this.errorMessage = this.getErrorMessage(e);
      } finally {
        this.loading = false;
      }
    },
  },
}
</script>